const actions = {
  FETCH_RUNS_REQUEST: 'runs/FETCH_RUNS_REQUEST',
  FETCH_RUNS_SUCCESS: 'runs/FETCH_RUNS_SUCCESS',
  FETCH_RUNS_FAILURE: 'runs/FETCH_RUNS_FAILURE',
  PUBLISH_RUN_REQUEST: 'runs/PUBLISH_RUN_REQUEST',
  PUBLISH_RUN_SUCCESS: 'runs/PUBLISH_RUN_SUCCESS',
  PUBLISH_RUN_FAILURE: 'runs/PUBLISH_RUN_FAILURE',
};

export default actions;
